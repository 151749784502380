import { render } from "@testing-library/react";
import "./Components.css";
import NavBar from "./NavBar";
import TopImage from "./TopImage";
import Footer from "./footer";
import RatesAnswer from "./RatesAnswer";
import { useState } from "react";

function Rates(props) {
  const [t, setT] = useState("default");
  const [s, setS] = useState("default");
  const [slider, setSlider] = useState(0);
  const [test, setTest] = useState(null);

  const handleClick = (event) => {
    // let sizeSelector = document.getElementById("size").value;
    // let sizeVal = sizeSelector.options[sizeSelector.selectedIndex].value;
    let typeSelector = document.getElementById("type");
    let typeVal = typeSelector.options[typeSelector.selectedIndex].value;
    setT(typeVal);
    setS(slider);
  };

  const handleChange = (event) => {
    let size = document.getElementById("size").value;
    setSlider(size);
  };

  const handleTestClick = async () => {
    let name = document.getElementById("Name").value;
    let email = document.getElementById("Email").value;
    let phone = document.getElementById("Phone").value;
    let address = document.getElementById("Address").value;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
    let nameProvided = name != '';
    let emailProvided = email != '';
    let phoneProvided = phone != '';
    let addressProvided = address != '';
    let phoneValid = phoneRe.test(phone);
    let emailValid = re.test(email);
    var output = "There was an error with your form submission:\n\n"

    if (!nameProvided) {
      output += "Please enter a name.\n";
    }
    if (!emailProvided) {
      output += "Please enter an email.\n";
    }
    if (!phoneProvided) {
      output += "Please enter a phone number.\n";
    }
    if (!addressProvided) {
      output += "Please enter an address"
    }
    if (!emailValid && emailProvided) {
      output += "Please enter a valid email.\n";
    }
    if (!phoneValid && phoneProvided) {
      output += "Please enter a valid phone number.\n";
    }
    if(nameProvided && emailProvided && phoneProvided && emailValid && phoneValid){
      document.getElementById("Name").value = "";
      document.getElementById("Email").value = "";
      document.getElementById("Phone").value = "";
      document.getElementById("Address").value = "";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var reqBody = JSON.stringify({
        Name: name,
        Email: email,
        Phone: phone,
        Address: address
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: reqBody,
        redirect: "follow",
      };

      fetch("https://fmdej26zdd.execute-api.us-east-2.amazonaws.com/dev", requestOptions)
      .then(response => response.text())
      .then(result => alert('Your information has been recieved, we will be in touch with you shortly!'))
      .catch(error => console.log('There was an error with your submission, please try again.'))
    }
    else{
      alert(output)
    }
  }

  return (
    <div className="overall-div">
      <NavBar />
      <TopImage />
      <div>
        <h1>Welcome to our contact page!</h1>
        <h2>
          Please input your name, email and phone number, and we will be in
          touch with you as soon as possible.
          {/* <br />
          This form includes all of the necessary information for us to contact
          you and discuss your property further. */}
        </h2>
      </div>
      <div className="rates-selector">
        <div>
          <label for="Name">
            Name:<span className="required">*</span>
          </label>
          <span>
            {" "}
            <input type="text" name="Name" id="Name" />
          </span>
          <br />
          <label for="Email">
            Email:<span className="required">*</span>{" "}
          </label>
          <span>
            {" "}
            <input type="email" name="Email" id="Email" />
          </span>
          <br />
          <label for="Phone">
            Phone:<span className="required">*</span>
          </label>
          <span>
            {" "}
            <input type="tel" name="Phone" id="Phone" />
          </span>
          <br />
          <label for="Address">
            Address:<span className="required">*</span>
          </label>
          <span>
            <input type="Text" name="Address" id="Address" />
          </span>
        </div>
        {/* <h3>
          Enter the type of work you would like to be done on your property.
        </h3>
        <select name="type" id="type">
          <option value="default">Type of Work</option>
          <option value="lawn">Lawn Mowing</option>
          <option value="mulching">Mulching</option>
          <option value="something">Something</option>
        </select>
        <h3>Estimate your property size to the nearest 10 feet.</h3>
        <h3>{slider}</h3>
        <input
          name="size"
          id="size"
          type="range"
          min="0"
          max="10000"
          step="10"
          onChange={handleChange}
        ></input> */}
        <button name="Send" id="Send" onClick={handleTestClick}>
          Submit
        </button>
      </div>
      {/* <RatesAnswer t={t} s={s} /> */}
      <Footer />
    </div>
  );
}

export default Rates;
