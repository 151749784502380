import "./Components.css";

function TopImage() {
  return (
    <div className="topImage">
      <div className="titleImage">
        <h1 style={{marginLeft:"0", marginRight:"0"}}>LAWN JOCKEYZ </h1>
      </div>
    </div>
  );
}

export default TopImage;
