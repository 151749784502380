import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Rates from "./components/Rates";
import Work from "./components/Work";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        {/* <Route exact path="/About" element={<About />}></Route> */}
        {/* <Route exact path="/Contact" element={<Contact />}></Route> */}
        <Route exact path="/Contact" element={<Rates />}></Route>
        <Route exact path="/Work" element={<Work />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
