import { render } from "@testing-library/react";
import "./Components.css";
import NavBar from "./NavBar";
import TopImage from "./TopImage";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
// import ChrisPicture from "./images/ChrisPicture2.jpg";
import Truck from "./images/Truck1.jpg";

function Home(props) {
  const navigate = useNavigate();
  const handleGalleryClick = () => {
    navigate("/Work");
  };
  const handleRatesClick = () => {
    navigate("/Rates");
  };
  const handleAboutClick = () => {
    navigate("/About");
  };
  const handleContactClick = () => {
    navigate("/Contact");
  };
  return (
    <div className="overall-div">
      <NavBar />
      <TopImage />
      <h1 className="PageHeader">Welcome to LawnJockeyz.com!</h1>
      <div>
        <img src={Truck} className="ChrisPicture"></img>
      </div>

      <h2 className="homeHeader">WHO WE ARE:</h2>
      <h3 className="homeSection">
        At LAWN JOCKEYZ, our goal is customer satisfaction with a healthy impact
        on the environment and our clients.
      </h3>
      <h2 className="homeHeader">WHAT WE DO:</h2>
      <h3 className="homeSection">
        We offer chemical-free lawn maintenance using mulch control systems on
        our mowers.
      </h3>
      <h2 className="homeHeader">WHAT OUR SCHEDULE LOOKS LIKE:</h2>
      <h3 className="homeSection">
        During March, July, August, September, October, and November, we offer
        bi-weekly service while the days are shorter and the grass is not
        growing as fast or thick.
        <br />
        From April through June, we offer weekly service due to the volume of
        grass doubling for those three months.
      </h3>
      <h2 className="homeHeader">WHY WE LANDSCAPE IN THIS WAY:</h2>
      <h3 className="homeSection">
        Instead of using toxic chemicals and fertilizers that negatively impact
        the environment and customers, we landscape using mulching. This process
        is healthier for the lawns and involves mulching the top part of the
        blades of grass where the nitrogen is stored to make the lawn healthier
        by building healthier soil and stronger roots while managing invasive
        weeds.
      </h3>

      <h2 className="homeHeader">SEASONAL SERVICES AND PROJECTS:</h2>
      <h3 className="homeSection">
        In addition to lawn mowing, we offer seasonal services and projects
        including property cleanup, storm cleanup, rock wall installations, tree work and
        hedging.
      </h3>

      {/* <h2 className="HomeText">
        At LAWN JOCKEYZ our goal is customer satisfaction while having a healthy
        impact on the environment and our clients. <br/>We offer chemical free lawn
        maintenance using mulch control systems on our mowers. <br/>We do bi weekly
        service during March, July, August, September, October and November when
        the days are shorter and the grass is not growing as fast or thick.
        During April, May and June we do weekly service for our clients while
        the volume of grass is nearly double. it is healthier for the lawns 
        to mulch the top part of the blades of grass
        where the nitrogen is that makes the lawn healthier, building healthier
        soil, stronger roots and helps in managing weeds without using toxic
        chemicals and fertilizers that impact the environment and the customer.
        We look forward to working with you.
      </h2> */}
      {/* <h3>
        We are a landscaping business located on Whidbey Island, Washington,
        serving all people south of Greenbank.
      </h3> */}
      {/* <h3>
        To learn more about the history and mission of Lawn Jockeyz, as well as
        read about our employees, visit our{" "}
        <a onClick={handleAboutClick} className="HomeLink">
          About
        </a>{" "}
        page. */}
      {/* </h3> */}
      <h2 className="HomeText">
        To see a more detailed sample of our work, including the seasonal services and projects, check out our{" "}
        <a onClick={handleGalleryClick} className="HomeLink">
          Gallery
        </a>{" "}
        page.
      </h2>
      {/* <h3>
        We desire to be as transparent as possible in terms of our rates for
        specific jobs. If you would like an estimate of the cost for working on
        your project, check out our interactive{" "}
        <a onClick={handleRatesClick} className="HomeLink">
          Rates
        </a>{" "}
        page for an estimate. Please keep in mind that every project is
        different and this tool is only for estimation purposes and may not
        accurately reflect the price of your project.
      </h3> */}
      <div style={{ paddingBottom: "0.5vh" }}>
        <h2 className="HomeText">
          If you would like to employ our services, or want to discuss your
          project and needs for a free estimate, please visit our{" "}
          <a onClick={handleContactClick} className="HomeLink">
            Contact
          </a>{" "}
          page.
        </h2>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
