import "./Components.css";
import NavBar from "./NavBar";
import TopImage from "./TopImage";
import Footer from "./footer";
import Before1 from "./images/Before1.jpg";
import After1 from "./images/After1.jpg";
import Before2 from "./images/BeforeLawn1.jpg";
import After2 from "./images/AfterLawn1.jpg";
import Truck from "./images/Truck1.jpg";
import RocksCloseUp from "./images/RocksCloseUp1.jpg";
import RocksFurther from "./images/RocksFurther1.jpg";
import BigBeach from "./images/BigBeach1.jpg";
import Rainbow from "./images/Rainbow1.jpg";
import LawnMower from "./images/LawnMower1.jpg";
import BigField from "./images/BigField1.jpg";
import BlackAndWhite from "./images/Black and White1.jpg";
import blurry from "./images/blurry.jpg";
import grass from "./images/grass.jpg";
import hedge from "./images/hedge.jpg";
import thirdPic from "./images/thirdPic.jpg";
import trees from "./images/trees.jpg";
import AfterHome from "./images/AfterHome.jpg";
import AfterLawn from "./images/AfterLawnmower.jpg";
import AfterSky from "./images/AfterSky.jpg";
import BeforeHome from "./images/BeforeHome.jpg";
import BeforeLawn from "./images/BeforeLawnmower.jpg";
import BeforeSky from "./images/BeforeSky.jpg";
import FirstFour from "./images/firstfourpic.jpg";
import SecondFour from "./images/secondfourpic.jpg";
import ThirdFour from "./images/thirdfourpic.jpg";
import FourthFour from "./images/fourthfourpic.jpg";
import FirstLeaf from "./images/firstleaf.jpg";
import SecondLeaf from "./images/secondleaf.jpg";
import LastLeaf from "./images/lastleaf.jpg";
import TreesProject from "./images/treesproject.jpg";
import SquareHedge from "./images/squarehedge.jpg";
import Sunset from "./images/sunset.jpg";
import FirstWall from "./images/firstwall.jpg";
import SecondWall from "./images/secondwall.jpg";
import ThirdWall from "./images/thirdwall.jpg";
import FinalWall from "./images/finalwall.jpg";


function Work(props) {
  return (
    <div className="overall-div">
      <NavBar />
      <TopImage />
      <h2 className="galleryHeader">
        At Lawn Jockeyz, we believe our work speaks for itself.
        <br />
        Before you contact us, we want to show you some examples of the progression of the high
        quality work that we do.
      </h2>
      <div style={{ alignItems: "center" }}>
        <table className="beforeAfter">
          <tr>
            <th>Before</th>
            <th>After</th>
          </tr>
          <tr>
            <td>
              <img src={Before1} className="before" />
            </td>
            <td>
              <img src={After1} />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={Before2} className="three-pics" />
            </td>
            <td>
              <img src={After2} className="three-pics" />
            </td>
            <td>
              <img src={thirdPic} className="three-pics" />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={RocksCloseUp} />
            </td>
            <td>
              <img src={RocksFurther} />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={FirstWall} className="four-pics" />
            </td>
            <td>
              <img src={SecondWall} className="four-pics" />
            </td>
            <td>
              <img src={ThirdWall} className="four-pics" />
            </td>
            <td>
              <img src={FinalWall} className="four-pics" />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={BeforeHome} />
            </td>
            <td>
              <img src={AfterHome} />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={BeforeLawn} />
            </td>
            <td>
              <img src={AfterLawn} />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={FirstFour} className="four-pics" />
            </td>
            <td>
              <img src={SecondFour} className="four-pics" />
            </td>
            <td>
              <img src={ThirdFour} className="four-pics" />
            </td>
            <td>
              <img src={FourthFour} className="four-pics" />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={FirstLeaf} className="three-pics" />
            </td>
            <td>
              <img src={SecondLeaf} className="three-pics" />
            </td>
            <td>
              <img src={LastLeaf} className="three-pics" />
            </td>
          </tr>
        </table>
        <table className="beforeAfter">
          <tr>
            <td>
              <img src={BeforeSky} />
            </td>
            <td>
              <img src={AfterSky} />
            </td>
          </tr>
        </table>
        <div className="gallery">
          <h1>Gallery</h1>
          <span>
            <img src={Truck} />
          </span>
          <span>
            <img src={Rainbow}></img>
          </span>
          <span>
            <img src={LawnMower} />
          </span>
          <span>
            <img src={BigBeach} />
          </span>
          <span>
            <img src={BigField}></img>
          </span>
          <span>
            <img src={BlackAndWhite} />
          </span>
          <span>
            <img src={blurry} />
          </span>
          <span>
            <img src={grass} />
          </span>
          <span>
            <img src={hedge} />
          </span>
          <span>
            <img src={trees} />
          </span>
          <span>
            <img src={Sunset} />
          </span>
          <span>
            <img src={SquareHedge} />
          </span>
          <span>
            <img src={TreesProject} />
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Work;
