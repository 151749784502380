import { Navigate, useNavigate } from "react-router-dom";
import "./Components.css";

function NavBar(props) {
  const navigate = useNavigate();
  const handleAboutClick = () => {
    navigate("/About");
  };
  const handleContactClick = () => {
    navigate("/Contact");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleWorkClick = () => {
    navigate("/Work");
  };
  const handleRatesClick = () => {
    navigate("/Contact");
  };
  return (
    <div className="nav-bar">
      <button onClick={handleHomeClick}>Home</button>
      {/* <button onClick={handleAboutClick}>About</button> */}
      <button onClick={handleWorkClick}>Gallery</button>
      <button onClick={handleRatesClick}>Contact</button>
      {/* <button onClick={handleContactClick}>Contact Us</button> */}
    </div>
  );
}

export default NavBar;
