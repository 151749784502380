import { useNavigate } from "react-router-dom";
import "./Components.css";

function Footer() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Contact");
  };

  return (
    <div className="footer-div">
      <div style={{ width:"100%", display:"flex",height:"min-content", alignItems:"center"}}>
        <div className="contact">
          <h2>
            Please call us at
            <br />
            360-331-7591
          </h2>
        </div>
        <div className="contact">
          <h2>
            Email us at
            <br />
            lawnjockeyz555@gmail.com
          </h2>
        </div>
        <div>
          <button onClick={handleClick} className="contactButton">
            Contact
          </button>
        </div>
      </div>
      <div style={{height: "min-content", width: "100%", display:"block", alignItems:"center"}}>
        <h3 style={{textAlign:"center", fontSize: "1.2vw"}}>Located on Whidbey Island in Clinton, WA, 98236</h3>
      </div>
    </div>
  );
}

export default Footer;
